export var ScreenType;
(function (ScreenType) {
    ScreenType["LISTS"] = "lists";
    ScreenType["TEXT"] = "text";
    ScreenType["TILE"] = "tile";
    ScreenType["WORKOUT"] = "workout";
})(ScreenType || (ScreenType = {}));
export var WorkoutSectionType;
(function (WorkoutSectionType) {
    WorkoutSectionType["SINGLE"] = "single";
})(WorkoutSectionType || (WorkoutSectionType = {}));
export var content = {
    name: 'swimblueprint',
    config: {
        isNavBarFixed: true,
        routes: {
            HOME: '/',
            NOT_FOUND: '/404',
        },
    },
    socials: [],
    hero: {
        description: "We build training plans so that you don't have to",
        ctaText: 'Build my workout',
        ctaUrl: '/#build',
    },
    sections: [
        {
            slug: 'about',
            title: 'About',
            type: ScreenType.TEXT,
            summary: '',
            description: "When it comes to swim training, we're experts.",
        },
        {
            slug: 'build',
            title: 'Build my plan',
            type: ScreenType.WORKOUT,
            summary: "We'll build you a training plan based on your specific needs.",
            description: 'Triathlon, marathon swimming, short course - we can do it all.',
            workoutSectionType: WorkoutSectionType.SINGLE,
            workoutEditEnabled: false,
        },
    ],
};
