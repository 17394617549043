var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { getTotalWorkoutDistance, WorkoutComponentRestType, WorkoutComponentType, } from '@swimblueprint/shared-utils';
export var WorkoutView = function (_a) {
    var workout = _a.workout;
    var workoutTotal = getTotalWorkoutDistance(workout);
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "workout-view card" }, { children: [_jsxs("h3", __assign({ className: "subtitle is-3" }, { children: [workout.name, " | ", workout.type, " | ", workoutTotal, "m"] })), _jsxs("table", __assign({ className: "workout-view table is-bordered" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Repeats" }), _jsx("th", { children: "Type" }), _jsx("th", { children: "Distance" }), _jsx("th", { children: "Duration" }), _jsx("th", { children: "Equipment" }), _jsx("th", { children: "Stroke" })] }) }), _jsx("tbody", { children: workout.components.map(function (component, index) {
                                return component.type === WorkoutComponentType.COOL_DOWN ||
                                    component.type === WorkoutComponentType.WARM_UP ||
                                    component.type === WorkoutComponentType.SWIM ? (_jsxs("tr", { children: [_jsx("td", {}), _jsx("td", { children: component.type }), _jsxs("td", { children: [component.distance, "m"] }), _jsx("td", {}), _jsx("td", { children: component.equipment }), _jsx("td", { children: component.stroke })] }, "".concat(index, "-").concat(component.type))) : component.type === WorkoutComponentType.REST ? (_jsxs("tr", { children: [_jsx("td", {}), _jsx("td", { children: component.type }), _jsx("td", {}), component.restType === WorkoutComponentRestType.DURATION ? (_jsxs("td", { children: [component.duration, "s"] })) : (_jsx("td", { children: component.restType })), _jsx("td", {}), _jsx("td", {})] }, "".concat(index, "-").concat(component.type))) : component.type === WorkoutComponentType.REPEAT ? (component.components.map(function (repeatComponent, innerIndex) { return (_jsxs("tr", { children: [innerIndex === 0 && (_jsx("td", __assign({ rowSpan: component.components.length }, { children: component.repeats }))), _jsx("td", { children: repeatComponent.type }), repeatComponent.type === WorkoutComponentType.SWIM ? (_jsxs("td", { children: [repeatComponent.distance, "m"] })) : (_jsx("td", {})), repeatComponent.type === WorkoutComponentType.SWIM ? (_jsx("td", {})) : repeatComponent.type === WorkoutComponentType.REST ? (repeatComponent.restType ===
                                            WorkoutComponentRestType.DURATION ? (_jsxs("td", { children: [repeatComponent.duration, "s"] })) : repeatComponent.restType ===
                                            WorkoutComponentRestType.LAP_PRESS ? (_jsx("td", { children: repeatComponent.restType })) : (_jsx("td", {}))) : (_jsx("td", {})), repeatComponent.type === WorkoutComponentType.SWIM ? (_jsx("td", { children: repeatComponent.equipment })) : (_jsx("td", {})), repeatComponent.type === WorkoutComponentType.SWIM ? (_jsx("td", { children: repeatComponent.stroke })) : (_jsx("td", {}))] }, "".concat(index, "-").concat(innerIndex, "-").concat(repeatComponent.type))); })) : (_jsx("td", {}));
                            }) })] }))] })) }));
};
