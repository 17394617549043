import { Equipment, WorkoutComponentRestType, WorkoutComponentStroke, WorkoutComponentType, WorkoutType, } from '../types';
export var exampleWorkouts = [
    {
        name: 'Pyramids',
        type: WorkoutType.TEMPO,
        components: [
            {
                type: WorkoutComponentType.WARM_UP,
                distance: 200,
                equipment: Equipment.NONE,
                stroke: WorkoutComponentStroke.FREE,
            },
            {
                type: WorkoutComponentType.REPEAT,
                repeats: 2,
                components: [
                    {
                        type: WorkoutComponentType.SWIM,
                        distance: 200,
                        equipment: Equipment.NONE,
                        stroke: WorkoutComponentStroke.FREE,
                    },
                    {
                        type: WorkoutComponentType.REST,
                        restType: WorkoutComponentRestType.DURATION,
                        duration: 30,
                    },
                    {
                        type: WorkoutComponentType.SWIM,
                        distance: 100,
                        equipment: Equipment.NONE,
                        stroke: WorkoutComponentStroke.FREE,
                    },
                    {
                        type: WorkoutComponentType.REST,
                        restType: WorkoutComponentRestType.DURATION,
                        duration: 20,
                    },
                    {
                        type: WorkoutComponentType.SWIM,
                        distance: 50,
                        equipment: Equipment.NONE,
                        stroke: WorkoutComponentStroke.FREE,
                    },
                    {
                        type: WorkoutComponentType.REST,
                        restType: WorkoutComponentRestType.DURATION,
                        duration: 10,
                    },
                    {
                        type: WorkoutComponentType.SWIM,
                        distance: 50,
                        equipment: Equipment.NONE,
                        stroke: WorkoutComponentStroke.FREE,
                    },
                    {
                        type: WorkoutComponentType.REST,
                        restType: WorkoutComponentRestType.DURATION,
                        duration: 10,
                    },
                    {
                        type: WorkoutComponentType.SWIM,
                        distance: 100,
                        equipment: Equipment.NONE,
                        stroke: WorkoutComponentStroke.FREE,
                    },
                    {
                        type: WorkoutComponentType.REST,
                        restType: WorkoutComponentRestType.DURATION,
                        duration: 20,
                    },
                    {
                        type: WorkoutComponentType.SWIM,
                        distance: 200,
                        equipment: Equipment.NONE,
                        stroke: WorkoutComponentStroke.FREE,
                    },
                    {
                        type: WorkoutComponentType.REST,
                        restType: WorkoutComponentRestType.DURATION,
                        duration: 30,
                    },
                ],
            },
            {
                type: WorkoutComponentType.COOL_DOWN,
                distance: 200,
                equipment: Equipment.NONE,
                stroke: WorkoutComponentStroke.FREE,
            },
        ],
    },
    {
        name: 'Drills',
        type: WorkoutType.TEMPO,
        components: [
            {
                type: WorkoutComponentType.WARM_UP,
                distance: 400,
                stroke: WorkoutComponentStroke.FREE,
                equipment: Equipment.NONE,
            },
            {
                type: WorkoutComponentType.REST,
                restType: WorkoutComponentRestType.LAP_PRESS,
            },
            {
                type: WorkoutComponentType.SWIM,
                distance: 300,
                stroke: WorkoutComponentStroke.DRILL,
                equipment: Equipment.PULL_BUOY,
            },
            {
                type: WorkoutComponentType.REST,
                restType: WorkoutComponentRestType.DURATION,
                duration: 30,
            },
            {
                type: WorkoutComponentType.SWIM,
                distance: 200,
                stroke: WorkoutComponentStroke.DRILL,
                equipment: Equipment.FINS,
            },
            {
                type: WorkoutComponentType.REST,
                restType: WorkoutComponentRestType.DURATION,
                duration: 20,
            },
            {
                type: WorkoutComponentType.SWIM,
                distance: 100,
                stroke: WorkoutComponentStroke.DRILL,
                equipment: Equipment.KICKBOARD,
            },
            {
                type: WorkoutComponentType.REST,
                restType: WorkoutComponentRestType.DURATION,
                duration: 10,
            },
            {
                type: WorkoutComponentType.COOL_DOWN,
                distance: 200,
                stroke: WorkoutComponentStroke.FREE,
                equipment: Equipment.NONE,
            },
        ],
    },
    {
        name: 'Easy',
        type: WorkoutType.EASY,
        components: [
            {
                type: WorkoutComponentType.WARM_UP,
                distance: 300,
                stroke: WorkoutComponentStroke.FREE,
                equipment: Equipment.NONE,
            },
            {
                type: WorkoutComponentType.SWIM,
                distance: 100,
                stroke: WorkoutComponentStroke.FREE,
                equipment: Equipment.NONE,
            },
            {
                type: WorkoutComponentType.REST,
                restType: WorkoutComponentRestType.DURATION,
                duration: 10,
            },
            {
                type: WorkoutComponentType.SWIM,
                distance: 100,
                stroke: WorkoutComponentStroke.FREE,
                equipment: Equipment.PULL_BUOY,
            },
            {
                type: WorkoutComponentType.REST,
                restType: WorkoutComponentRestType.DURATION,
                duration: 10,
            },
            {
                type: WorkoutComponentType.SWIM,
                distance: 100,
                stroke: WorkoutComponentStroke.FREE,
                equipment: Equipment.PADDLES,
            },
            {
                type: WorkoutComponentType.REST,
                restType: WorkoutComponentRestType.DURATION,
                duration: 10,
            },
            {
                type: WorkoutComponentType.COOL_DOWN,
                distance: 400,
                stroke: WorkoutComponentStroke.FREE,
                equipment: Equipment.NONE,
            },
        ],
    },
    {
        name: 'Tempo',
        type: WorkoutType.TEMPO,
        components: [
            {
                type: WorkoutComponentType.WARM_UP,
                distance: 400,
                stroke: WorkoutComponentStroke.FREE,
                equipment: Equipment.NONE,
            },
            {
                type: WorkoutComponentType.REPEAT,
                repeats: 2,
                components: [
                    {
                        type: WorkoutComponentType.SWIM,
                        distance: 200,
                        stroke: WorkoutComponentStroke.FREE,
                        equipment: Equipment.NONE,
                    },
                    {
                        type: WorkoutComponentType.REST,
                        restType: WorkoutComponentRestType.DURATION,
                        duration: 20,
                    },
                    {
                        type: WorkoutComponentType.SWIM,
                        distance: 200,
                        stroke: WorkoutComponentStroke.DRILL,
                        equipment: Equipment.FINS,
                    },
                    {
                        type: WorkoutComponentType.REST,
                        restType: WorkoutComponentRestType.DURATION,
                        duration: 20,
                    },
                ],
            },
            {
                type: WorkoutComponentType.COOL_DOWN,
                distance: 400,
                stroke: WorkoutComponentStroke.FREE,
                equipment: Equipment.NONE,
            },
        ],
    },
];
