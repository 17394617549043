var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchAsync, selectWorkout } from '../../features/workout/workoutSlice';
import { WorkoutView } from '../molecules';
export var SectionWorkout = function (_a) {
    var workoutSection = _a.workoutSection;
    var title = workoutSection.title, description = workoutSection.description;
    var workout = useAppSelector(selectWorkout);
    var dispatch = useAppDispatch();
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "hero-body" }, { children: _jsxs("div", __assign({ className: "container has-text-centered" }, { children: [_jsx("p", __assign({ className: "title" }, { children: title })), _jsx("div", { children: description }), _jsx("button", __assign({ className: "button", "aria-label": "Build workout", onClick: function () { return dispatch(fetchAsync()); } }, { children: "Build workout" })), _jsx("div", { children: workout.status === 'failed' ? ('error loading') : workout.status === 'loading' ? ('loading workout') : workout.status === 'idle' && workout.value ? (_jsx(WorkoutView, { workout: workout.value })) : ('') })] })) })) }));
};
