export var Equipment;
(function (Equipment) {
    Equipment["NONE"] = "none";
    Equipment["FINS"] = "fins";
    Equipment["KICKBOARD"] = "kickboard";
    Equipment["PADDLES"] = "paddles";
    Equipment["PULL_BUOY"] = "pull_buoy";
    Equipment["SNORKEL"] = "snorkel";
})(Equipment || (Equipment = {}));
export var PoolLength;
(function (PoolLength) {
    PoolLength[PoolLength["TWENTY_FIVE"] = 25] = "TWENTY_FIVE";
    PoolLength[PoolLength["FIFTY"] = 50] = "FIFTY";
})(PoolLength || (PoolLength = {}));
export var Units;
(function (Units) {
    Units["METERS"] = "meters";
    Units["YARDS"] = "yards";
})(Units || (Units = {}));
export var WorkoutComponentType;
(function (WorkoutComponentType) {
    WorkoutComponentType["WARM_UP"] = "warm_up";
    WorkoutComponentType["SWIM"] = "swim";
    WorkoutComponentType["COOL_DOWN"] = "cool_down";
    WorkoutComponentType["REST"] = "rest";
    WorkoutComponentType["REPEAT"] = "repeat";
})(WorkoutComponentType || (WorkoutComponentType = {}));
export var WorkoutComponentStroke;
(function (WorkoutComponentStroke) {
    WorkoutComponentStroke["FREE"] = "free";
    WorkoutComponentStroke["DRILL"] = "drill";
})(WorkoutComponentStroke || (WorkoutComponentStroke = {}));
export var WorkoutComponentRestType;
(function (WorkoutComponentRestType) {
    WorkoutComponentRestType["DURATION"] = "duration";
    WorkoutComponentRestType["LAP_PRESS"] = "lap_press";
})(WorkoutComponentRestType || (WorkoutComponentRestType = {}));
export var WorkoutType;
(function (WorkoutType) {
    WorkoutType["EASY"] = "easy";
    WorkoutType["TEMPO"] = "tempo";
    WorkoutType["THRESHOLD"] = "threshold";
    WorkoutType["VO2"] = "vo2";
    WorkoutType["MAX"] = "max";
})(WorkoutType || (WorkoutType = {}));
