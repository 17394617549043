var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
export var NavBar = function (_a) {
    var fixed = _a.fixed, content = _a.content;
    var _b = useState(false), isBurgerMenuActive = _b[0], setisBurgerMenuActive = _b[1];
    var hash = useLocation().hash;
    var name = content.name, HOME = content.config.routes.HOME, sections = content.sections;
    var toggleBurgerMenu = function () {
        setisBurgerMenuActive(!isBurgerMenuActive);
    };
    var handleLogoMenuItemClick = function () {
        if (isBurgerMenuActive) {
            toggleBurgerMenu();
        }
    };
    var currentHash = hash.slice(1);
    return (_jsxs("nav", __assign({ className: "navbar ".concat(fixed ? 'is-fixed-top' : ''), role: "navigation", "aria-label": "main navigation" }, { children: [_jsxs("div", __assign({ className: "navbar-brand" }, { children: [_jsx(Link, __assign({ to: HOME, onClick: handleLogoMenuItemClick, className: "navbar-item" }, { children: _jsx("b", { children: name }) })), _jsx("a", __assign({ role: "button", className: "navbar-burger", "aria-label": "menu", "aria-expanded": "false", "data-target": "navbarBasic", onClick: toggleBurgerMenu }, { children: _jsxs("div", __assign({ className: "burger-icon".concat(isBurgerMenuActive ? '-is-active' : '') }, { children: [_jsx("span", { "aria-hidden": "true" }), _jsx("span", { "aria-hidden": "true" }), _jsx("span", { "aria-hidden": "true" })] })) }))] })), _jsx("div", __assign({ id: "navbarBasic", className: "navbar-menu ".concat(isBurgerMenuActive ? 'is-active' : '') }, { children: _jsx("div", __assign({ className: "navbar-start" }, { children: sections.map(function (section, index) {
                        return (_jsx(Link, __assign({ to: "/#".concat(section.slug), onClick: toggleBurgerMenu, className: "navbar-item".concat(section.slug === currentHash ? ' is-current-page' : '') }, { children: section.title }), "".concat(index, "-").concat(section.slug)));
                    }) })) }))] })));
};
