import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes as ReactRouterRoutes, useLocation, } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { content } from './content';
import { Footer } from './components/molecules';
import { NavBar } from './components/organisms';
import { PageHome, PageNotFound } from './components/pages';
function App() {
    var name = content.name, _a = content.config, isNavBarFixed = _a.isNavBarFixed, _b = _a.routes, HOME = _b.HOME, NOT_FOUND = _b.NOT_FOUND;
    var _c = useLocation(), pathname = _c.pathname, hash = _c.hash, key = _c.key;
    useEffect(function () {
        if (isNavBarFixed) {
            var html = window.document.querySelector('html');
            html === null || html === void 0 ? void 0 : html.classList.add('has-navbar-fixed-top');
        }
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        else {
            setTimeout(function () {
                var id = hash.replace('#', '');
                var element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key, isNavBarFixed]);
    return (_jsxs(_Fragment, { children: [_jsx(NavBar, { fixed: isNavBarFixed, content: content }), _jsxs(ReactRouterRoutes, { children: [_jsx(Route, { path: HOME, element: _jsx(PageHome, { content: content }) }), _jsx(Route, { path: NOT_FOUND, element: _jsx(PageNotFound, { siteTitle: name }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: NOT_FOUND }) })] }), _jsx(Footer, { siteTitle: name })] }));
}
function AppWrapper() {
    return (_jsx(BrowserRouter, { children: _jsx(HelmetProvider, { children: _jsx(App, {}) }) }));
}
export default AppWrapper;
