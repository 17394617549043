import { WorkoutComponentType, } from '../types';
export var getRandomArrayIndex = function (arr) {
    return Math.floor(Math.random() * arr.length);
};
export var getTotalWorkoutDistance = function (workout) {
    var _a;
    return (_a = workout === null || workout === void 0 ? void 0 : workout.components) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, currComponent) {
        var _a;
        if (currComponent.type === WorkoutComponentType.WARM_UP ||
            currComponent.type === WorkoutComponentType.SWIM ||
            currComponent.type === WorkoutComponentType.COOL_DOWN) {
            return acc + currComponent.distance;
        }
        else if (currComponent.type === WorkoutComponentType.REPEAT) {
            var repeatsTotal = (_a = currComponent === null || currComponent === void 0 ? void 0 : currComponent.components) === null || _a === void 0 ? void 0 : _a.reduce(function (innerAcc, innerCurrComponent) {
                if (innerCurrComponent.type === WorkoutComponentType.WARM_UP ||
                    innerCurrComponent.type === WorkoutComponentType.SWIM ||
                    innerCurrComponent.type === WorkoutComponentType.COOL_DOWN) {
                    return innerAcc + innerCurrComponent.distance;
                }
                else {
                    return innerAcc;
                }
            }, 0);
            return acc + repeatsTotal * currComponent.repeats;
        }
        else {
            return acc;
        }
    }, 0);
};
