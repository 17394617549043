import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { Helmet } from '../molecules';
import { SectionContainer, SectionHero } from '../organisms';
export var PageHome = function (_a) {
    var content = _a.content;
    var hash = useLocation().hash;
    var name = content.name, isNavBarFixed = content.config.isNavBarFixed, hero = content.hero, sections = content.sections;
    var helmetTitle = hash
        ? hash.charAt(1).toUpperCase() + hash.slice(2) + ' | ' + name
        : name;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { title: helmetTitle, description: helmetTitle }), _jsx(SectionHero, { siteTitle: name, hero: hero }), sections.map(function (section, index) {
                var maxIndex = sections.length - 1;
                return (_jsx(SectionContainer, { isNavBarFixed: isNavBarFixed, screenSection: section, isPrimaryColorScheme: index % 2 !== 0, isNotLast: index !== maxIndex }, "".concat(index, "-").concat(section.slug)));
            })] }));
};
