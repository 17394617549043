var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var SectionHero = function (_a) {
    var siteTitle = _a.siteTitle, hero = _a.hero;
    return (_jsx("section", __assign({ className: "hero is-primary is-top is-medium" }, { children: _jsx("div", __assign({ className: "hero-body" }, { children: _jsxs("div", __assign({ className: "container has-text-centered" }, { children: [_jsx("p", __assign({ className: "title" }, { children: siteTitle })), _jsx("p", __assign({ className: "subtitle" }, { children: hero.description })), _jsx("a", __assign({ href: hero.ctaUrl }, { children: _jsx("button", __assign({ className: "button" }, { children: hero.ctaText })) }))] })) })) })));
};
